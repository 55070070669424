import { useState } from "react";
import "./App.css";
import { Button, message, Space } from "antd";

function App() {
  const [ans, setAns] = useState("___");
  return (
    <div className="App">
      <header className="App-header">
        <img
          src="https://picbedd.oss-cn-beijing.aliyuncs.com/20241210225201.png"
          className="App-logo"
          alt="logo"
        />
        <p>{ans}是一个大坏蛋</p>
        <Space size="middle">
          <Button
            size="large"
            type="primary"
            onClick={() => {
              setAns("KK ");
              message.success("恭喜回答正确");
            }}
          >
            KK
          </Button>
          <Button
            size="large"
            type=""
            onClick={() => {
              if (ans === "KK ") setAns("___");
              message.error("答错了，打pp");
            }}
          >
            QQ
          </Button>
        </Space>
      </header>
    </div>
  );
}

export default App;
